import { api } from './api';

const PORT_HTTP = '5089'
const BASE_DOMAIN = `http://195.35.16.176:`

const PATH_JOBS = '/api/v1/jobs';
const PATH_USERS = '/api/v1/users';
const PATH_CUMRRICULUMS = '/api/v1/users/curriculums';
const PATH_REQUIREMENTS = '/api/v1/requirements';
const PATH_BENEFITS = '/api/v1/benefits';

export const GetJobs = async () => {
    const result = await api.get(`${BASE_DOMAIN}${PORT_HTTP}${PATH_JOBS}`)
        .then((response) => response.data)
        .catch((error) => error)

    if (!(result instanceof Error)) {
        return { data: result }
    } else {
        return {
            error: {
                code: "400", title: "Job Get:", description: "Erro ao buscar vagas"
            }
        }
    }
}
export const GetJobID = async (id: string) => {
    const result = await api.get(`${BASE_DOMAIN}${PORT_HTTP}${PATH_JOBS}/${id}`)
        .then((response) => response.data)
        .catch((error) => error)

    if (!(result instanceof Error)) {
        return { data: result }
    } else {
        return {
            error: {
                code: "400", title: "Job Get:", description: "Erro ao buscar vaga"
            }
        }
    }
}
export const PostJobs = async (data: {}) => {
    const result = await api.post(`${BASE_DOMAIN}${PORT_HTTP}${PATH_JOBS}`, data)
        .then((response) => response.data)
        .catch((error) => error)

    if (!(result instanceof Error)) {
        return { data: result }
    } else {
        return {
            error: {
                code: "400", title: "Job Creator", description: "Erro ao criar vaga"
            }
        }
    }
}
export const handleImageChange = async (e: { target: { files: (string | Blob)[]; }; }) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      // Simulando o envio da imagem para um servidor fictício usando a biblioteca axios
      const response = await api.post(`${BASE_DOMAIN}3000/upload`, formData);

      // Atualiza a visualização com a nova imagem
      //setImageSrc(response.data.imageUrl);
    } catch (error) {
      console.error('Erro ao enviar a imagem:', error);
    }
  };
export const requestUsers = async () => {
    const result = await api.get(`${BASE_DOMAIN}${PORT_HTTP}${PATH_USERS}`)
        .then((response) => response.data)
        .catch((error) => error)

    if (!(result instanceof Error)) {
        return { data: result }
    } else {
        return {
            error: {
                code: "400", title: "Get User:", description: "Erro ao buscar usuários"
            }
        }
    }
}
export const requestCurriculums = async () => {
    const result = await api.get(`${BASE_DOMAIN}${PORT_HTTP}${PATH_CUMRRICULUMS}`)
        .then((response) => response.data)
        .catch((error) => error)

    if (!(result instanceof Error)) {
        return { data: result }
    } else {
        return {
            error: {
                code: "400", title: "Get CV:", description: "Erro ao buscar curriculos"
            }
        }
    }
}
export const requestRequirements = async () => {
    const result = await api.get(`${BASE_DOMAIN}${PORT_HTTP}${PATH_REQUIREMENTS}`)
        .then((response) => response.data)
        .catch((error) => error)

    if (!(result instanceof Error)) {
        return { data: result }
    } else {
        return {
            error: {
                code: "400", title: "Get Req:", description: "Erro ao buscar requisitos"
            }
        }
    }
}
export const requestBenefits = async () => {
    const result = await api.get(`${BASE_DOMAIN}${PORT_HTTP}${PATH_BENEFITS}`)
        .then((response) => response.data)
        .catch((error) => error)

    if (!(result instanceof Error)) {
        return { data: result }
    } else {
        return {
            error: {
                code: "400", title: "Get Ben", description: "Erro ao buscar beneficios"
            }
        }
    }
}

import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControlLabel } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Success from "../Success";
import Popup from "../../Components/Popup";
import { ButtonSend, ButtonSendGroup, Container, ContainerIcon, CustomForm, CustomGrid, CustomPaper, CustomTextField, HeaderItens, IconEditImage } from "./style";
import { PopupData } from "../../Components/Popup/ComponentPopup";
import { CustomToggleButton } from "./styleToogle";
import { benefitsArray, categorysArray, locations, skillsArray } from "./ComponentForm";
import IconDefault from "../../assets/icon-default.png";
import PencilEdit from "../../assets/pencil-edit.svg";
import HeaderPerfil from "../../Components/HeaderPerfil";
import { PostJobs } from "../../service/request";

interface Benefit {
	benefit: string;
}

interface Requirement {
	Job: string;
	TitleRequirement: string;
}

interface FormData {
	Title: string;
	Image: string;
	Location: string;
	IsPCD: boolean;
	CategoryJob: string;
	Benefits: Benefit[];
	Requirements: Requirement[];
	Salary: number;
	Description: string;
	CompanyName: string;
	CompanyEmail: string;
	Tag: string;
	ExpireAt: string;
}

export function CreateJobs() {
	const [showSuccess, setSuccess] = useState(false);
	const [popup, setPopup] = useState(false);
	const [popupData, setPopupData] = useState<PopupData>();

	const [dataBenefits, setDataBenefits] = useState(benefitsArray.map((item) => ({ ...item, selected: false })));;
	const [dataSkills, setDataSkills] = useState(skillsArray.map((item) => ({ ...item, selected: false })));

	const {
		handleSubmit,
		control,
		register,
	} = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = async (dataForm) => {

		dataForm.Benefits = dataBenefits.filter((x) => x.selected).map((selectedBenefit) => ({ benefit: selectedBenefit.benefit }));
		dataForm.Requirements = dataSkills.filter((x) => x.selected).map((selectedSkill) => ({ Job: selectedSkill.job, TitleRequirement: selectedSkill.skill }));
		console.log(dataForm);
		const { data, error } = await PostJobs(dataForm).finally(() => { console.log("Finalizado") });

		if (data) {
			setSuccess(true);
		}
		if (error) {
			setPopup(true);
			setPopupData({ code: error.code, title: error.title, description: error.description, textButton: "Tentar novamente" });
		}

	};

	const handleToggleBenefits = (index: number) => {
		setDataBenefits((prevData) => {
			const newData = [...prevData];
			newData[index] = {
				...newData[index],
				selected: !newData[index].selected,
			};
			return newData;
		});
	};
	const handleToggleSkills = (index: number) => {
		setDataSkills((prevData) => {
			const newData = [...prevData];
			newData[index] = {
				...newData[index],
				selected: !newData[index].selected,
			};
			return newData;
		});
	};


	useEffect(() => {
	}, [dataBenefits]);

	return (
		<>
			<HeaderPerfil backButton={true} notificationButton={false} addJobButton={false} />
			{popup && popupData && (
				<Popup dataPopup={popupData} />
			)}
			{showSuccess ? (
				<Success />
			) : (
				<Container>
					<HeaderItens><h2>Publicar Vaga</h2></HeaderItens>
					<CustomForm onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px', paddingBottom: '50px' }}>
						<CustomGrid container sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<CustomGrid item sx={{ width: '30%' }}>
								<ContainerIcon>
									<IconEditImage src={PencilEdit} />
									<img src={IconDefault} alt="Icone da vaga" {...register('Image', { value: "" })} />
								</ContainerIcon>
							</CustomGrid>
							<CustomGrid item sx={{ width: '70%' }}>
								<CustomTextField size="small" label="Nome da Empresa" {...register('CompanyName')} />
							</CustomGrid>
						</CustomGrid>
						<CustomTextField size="small" label="Titulo da Vaga" {...register('Title')} />
						<CustomTextField size="small" label="Email da Empresa" {...register('CompanyEmail')} />
						<CustomTextField size="small" label="Tag" {...register('Tag')} />
						<CustomTextField
							size="small"
							select
							label="Local da Vaga"
							{...register('Location')}
							fullWidth
						>
							{locations.map((location) => (
								<MenuItem key={location} value={location}>
									{location}
								</MenuItem>
							))}
						</CustomTextField>
						<CustomTextField size="small" rows={4} multiline label="Descrição da Vaga" {...register('Description')} />
						<FormControlLabel control={<Checkbox {...register('IsPCD')} />} label="Vaga para PCD?" sx={{ width: '100%' }} />
						<CustomGrid container>
							<CustomGrid item xs={6}>
								<CustomTextField
									size="small"
									select
									label="Categoria da Vaga"
									{...register('CategoryJob')}
									fullWidth
								>
									{categorysArray.map((categoria) => (
										<MenuItem key={categoria} value={categoria}>
											{categoria}
										</MenuItem>
									))}
								</CustomTextField>
							</CustomGrid>
							<CustomGrid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<Controller
										control={control}
										name="ExpireAt"
										render={({ field }) => (
											<DesktopDatePicker
												label="Data Limite"
												format="DD/MM/YYYY"
												slotProps={{ textField: { size: 'small' } }}
												value={field.value || null}  // Garante que value não é undefined
												onChange={(value) => {
													const dateValue = dayjs.isDayjs(value) ? value.toDate() : value;
													const stringValue = dayjs(dateValue).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
													console.log(stringValue);
													field.onChange(stringValue);
												}}
											/>
										)}
									/>
								</LocalizationProvider>
							</CustomGrid>
						</CustomGrid>
						<CustomTextField size="small" label="Salário da vaga" type="number" {...register('Salary')} />
						<HeaderItens>
							<h2>Beneficios</h2>
							<p>Selecione os beneficios para a vaga</p>
						</HeaderItens>

						<CustomPaper
							elevation={0}
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								'& > :not(style)': {
									m: 0.5,
									width: 'auto',
									height: 'auto',
								}
							}}
						>
							{dataBenefits.map((x, key) => (
								<CustomToggleButton
									key={key}
									size="small"
									value="check"
									selected={x.selected}
									onChange={() => handleToggleBenefits(key)}
								>
									{x.benefit}
								</CustomToggleButton>
							))}
						</CustomPaper>
						<HeaderItens>
							<h2>Requisitos</h2>
							<p>Selecione os requisitos para a vaga</p>
						</HeaderItens>
						<CustomPaper
							elevation={0}
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								'& > :not(style)': {
									m: 0.5,
									width: 'auto',
									height: 'auto',
								}
							}}
						>
							{dataSkills.map((x, key) => (
								<CustomToggleButton
									key={key}
									size="small"
									value="check"
									selected={x.selected}
									onChange={() => handleToggleSkills(key)}
								>
									{x.skill}
								</CustomToggleButton>
							))}
						</CustomPaper>
						<ButtonSendGroup>
							<ButtonSend type="submit" variant="contained" color="primary">
								Enviar Vaga
							</ButtonSend>
						</ButtonSendGroup>
					</CustomForm>
				</Container>
			)}
		</>
	);
};

export default CreateJobs;
import React from 'react';
import { Card, Instructor, Title, Image, ContentImage, ContentInfoCard, ButtonOptions, BarProgress, BackgroundProgress, InfoProgress, ClassificationButton, Stars } from './style';

interface CourseCardProps {
  title: string;
  image: string;
  instructor: string;
  progressWidth: string;
}


const CardCourse: React.FC<CourseCardProps> = ({ title, image, instructor, progressWidth }) => (
  <Card>
    <ContentImage>
      <Image src={image} alt="Course" />
      <ButtonOptions>...</ButtonOptions>
    </ContentImage>
    <ContentInfoCard>
      <Title href="/course"> {title} </Title>
      <Instructor>{instructor}</Instructor>
      <BackgroundProgress>
        <BarProgress width={progressWidth}/>
      </BackgroundProgress>
      <InfoProgress>{progressWidth} concluido
        <ClassificationButton>
          <Stars>★★★★★</Stars>
          <p>Sua classificação</p>
        </ClassificationButton>
      </InfoProgress>
    </ContentInfoCard>
  </Card>
);

export default CardCourse;
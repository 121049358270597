import axios from "axios";

export interface IError {
    detail: string;
    code?: string;
}

function apiClient() {
    const BASE_DOMAIN = ""
    const token = localStorage.getItem("token")
    const timeout = 20000;
    const api = axios.create({
        baseURL: BASE_DOMAIN,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": token,
        }
    })
    return api;
}

export const api = apiClient()
import React, { useState } from 'react';
import Sidebar from '../../Components/SideBarCourse';
import CourseCard from '../../Components/CardCourse';
import { HomeContainer, ContentContainer, MenuButton, TopBar } from './style';

import ImageCurriculum from '../../assets/curriculum.jpg';
import ImageExcel from '../../assets/excel.jpg';

import MenuIcon from '@mui/icons-material/Menu';

const Couser: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <HomeContainer>
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
      <TopBar isOpen={isSidebarOpen}>
          <MenuButton onClick={toggleSidebar}><MenuIcon /></MenuButton>
          <h2>Empregos Recife Cursos</h2>
      </TopBar>
      <ContentContainer isOpen={isSidebarOpen}>
        <CourseCard
          image={ImageExcel}
          title="Excel básico ao avançado: Aprenda a dominar a ferramenta"
          instructor="Daniel Duarte" 
          progressWidth='50%' />
        <CourseCard
          image={ImageCurriculum}
          title="Como Fazer Um Currículo Revolucionário: O Guia Completo" 
          instructor="Empregos Recife" 
          progressWidth='10%'/>
      </ContentContainer>
    </HomeContainer>
  );
};

export default Couser;
import React from "react";
import IconDefault from "../../assets/icon-default.png";

import {Container, Title, Logo, ThinParagraph} from "./style";

interface HeaderJobProps {
    title: string;
    description: string;
    avatar: string;
}

const HeaderJob:React.FC<HeaderJobProps> = ({title, description, avatar}) => {
    return (
        <Container>
            <Logo src={avatar || IconDefault}/>
            <Title>
                {title || "Lislaser"}
            </Title>
            <ThinParagraph>
                {description || "depilação"}
            </ThinParagraph>
        </Container>
    );
};

export default HeaderJob;
import styled from "styled-components";

export const Button = styled.div`
    display: flex;
    margin-bottom: 1rem;
    img{
        display: block;
        width: 2rem;
        height: 2rem;
    }
    &:hover{
        cursor: pointer;
    }
`;
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
`;
export const ContainerTop = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;
export const Title = styled.h1`
    font-size: 1.2rem;
    color: #414141;
    text-align: center;
`;
export const Description = styled.p`
    padding-top: 1rem;
    font-size: 1rem;
    color: #777;
    text-align: center;
`;
export const ContainerBottom = styled.div`
    display: flex;
    flex: 1;
    width: 70%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
`;
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
`;
export const GroupDots = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
export const PaginationDot = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
`;
export const ActiveDot = styled.div`
  background-color: #3498db;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
`;
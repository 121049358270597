import React from "react";
import {ContentTag} from "./style";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    text: JSX.Element | JSX.Element[] | string;
}
const ButtonTag:React.FC<IButton> = ({text}) => {
    return (
        <ContentTag>
            {text}
        </ContentTag>
    );
};

export default ButtonTag;
import { styled } from '@mui/system'
import { ToggleButton } from "@mui/material";

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    backgroundColor: '#E7EAEC',
    borderRadius: '25px',
    border: 'none',
    color: '#414141',
    '&.Mui-selected': {
        backgroundColor: '#0FB8E3',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0FB8E3',
            color: '#fff',
        },
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#0FB8E3',
        color: '#fff',
    },
    '&:hover': {
        backgroundColor: '#E7EAEC',
        color: '#414141',
    },
}));
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Content,
    ContentButton,
    ContentInput,
    ContentRecover,
    FormContent,
    Header,
    ImgLogo,
    SubTitle,
    Title
} from '../Login/style';
import Button from "../../Components/Button";
import EmpregosRecLogo from "../../assets/emprego-recife-logo.svg";
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomGrid, CustomTextField } from '../CreateJobs/style';
import { locations, nationalityArray, statesArray } from '../CreateJobs/ComponentForm';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ActiveDot, GroupDots, PaginationDot } from '../Starting/style';
import { ButtonSkip } from './style';
import PopupUserCV from '../../Components/PopupUserCV';

type Login = {
    name: string
    email: string
    password: string
}

const formPageArray = [
    {
        idForm: 1,
    },
    {
        idForm: 2,
    },
    {
        idForm: 3,
    }
]


const CreateUser = () => {
    const [currentForm, setForm] = useState(0);
    const [popupUserCV, setPopupUserCV] = useState(false);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Login>({})
    function handleLogin(data: Login) {
        console.log({ data })
    }
    function handleSkip() {
        setPopupUserCV(true);
    }
    function handleNextForm() {
        if (currentForm < formPageArray.length - 1) {
            setForm(currentForm + 1)
        } else {
            navigate('/home');
            //checar se o usuario foi registrado se sim navegue para home
        }
    }

    return (
        <Content>
            { popupUserCV && (
                <PopupUserCV />
            )}
            <Header>
                <ImgLogo src={EmpregosRecLogo} alt="Empregos Recife" />
                <Title>Editando seu</Title>
                <SubTitle>Perfil</SubTitle>
            </Header>
            <FormContent onSubmit={handleSubmit(handleLogin)}>
                <ContentInput>
                    <TextField 
                        size="small" 
                        placeholder='Ex: João da Silva'
                        {...register("name")} label="Nome Completo" />
                    <p>{errors.name?.message}</p>
                </ContentInput>
                <CustomGrid container spacing={2}>
                    <CustomGrid item xs={6}>
                        <CustomTextField
                            size="small"
                            select
                            label="Nacionalidade"
                            fullWidth
                        >
                            {nationalityArray.map((nationality) => (
                                <MenuItem key={nationality} value={nationality}>
                                    {nationality}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </CustomGrid>
                    <CustomGrid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Data de Nascimento"
                                format="DD/MM/YYYY"
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(value) => {
                                    const dateValue = dayjs.isDayjs(value) ? value.toDate() : value;
                                    console.log(dateValue);
                                }}
                            />
                        </LocalizationProvider>
                    </CustomGrid>
                </CustomGrid>
                <ContentInput>
                    <TextField size="small" sx={{ width: "100%", background: "transparent" }} {...register("name")} label="N Telefone ou Celular" />
                    <p>{errors.name?.message}</p>
                </ContentInput>
                <ContentRecover>Onde você mora atualmente?</ContentRecover>
                <CustomGrid container spacing={2}>
                    <CustomGrid item xs={6}>
                        <CustomTextField
                            size="small"
                            select
                            label="Estado"
                            fullWidth
                            
                        >
                            {statesArray.map((state) => (
                                <MenuItem key={state} value={state}>
                                    {state}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </CustomGrid>
                    <CustomGrid item xs={6}>
                        <CustomTextField
                            size="small"
                            select
                            label="Municipio"
                            fullWidth
                        >
                            {locations.map((location) => (
                                <MenuItem key={location} value={location}>
                                    {location}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </CustomGrid>
                </CustomGrid>
                <ContentButton style={{marginTop: '45%'}}>
                    <ButtonSkip onClick={handleSkip}>Pular</ButtonSkip>
                    <GroupDots style={{padding: '0 3rem'}}>
                        {formPageArray.map((_, index) => (
                            index === currentForm ? <ActiveDot key={index} /> : <PaginationDot key={index} />
                        ))}
                    </GroupDots>
                    <Button 
                        text={currentForm === formPageArray.length - 1 ? 'Finalizar' : 'Próximo'} onClick={handleNextForm} />
                </ContentButton>
            </FormContent>
        </Content>
    );
}

export default CreateUser;
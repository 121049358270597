import { Button, Grid, Paper, TextField } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.6rem;
    margin: 0 2rem;
`;
export const CustomForm = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
export const ButtonSendGroup = styled.div`
    margin-top: 1rem;
`;
export const HeaderItens = styled.div`
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 650px;
`;
export const ContainerIcon = styled.div`
    position: relative;
    display: inline-block;
    padding: 0.5rem;
`;
export const IconEditImage = styled.img`
    position: absolute;
    background-color: #fff;
    margin: 0.5rem;
    border-radius: 25px;
    bottom: 0;
    right: 0;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
`;
export const CustomTextField = styled(TextField)`
    &.MuiFormControl-root {
        width: 100%;
        margin-bottom: 10px;
    }
`;
export const ButtonSend = styled(Button)({

});
export const CustomPaper = styled(Paper)({
    maxWidth: '650px',
});
export const CustomGrid = styled(Grid)({
    maxWidth: '650px',
    paddingRight: '1rem',
});
import react from 'react';
import { Container, GroupDescription, Header, ImageAvatar, Window, WindowWrapper } from './style';
import Button from '../Button';
import Yeah from '../../assets/mascot/1.png';
import { useNavigate } from 'react-router-dom';
const PopupUserCV = () => {
    const navigate = useNavigate();
    function handleGoToHome() {
        navigate('/home');
    }
    return (
        <Container>
            <Window>
                <WindowWrapper>
                    <Header>
                        <h2>Lembre-se de terminar o <span style={{ color: '#0FB8E3'}}>Curriculum Vitae</span></h2>
                    </Header>
                    <GroupDescription>
                        <p>Para começar a procurar vagas de empregos é necessario fazer um curriculum na Empregos Recife.</p>
                        <p style={{ marginTop: 20 }}> Vá em perfil e termine</p>
                    </GroupDescription>
                    <Button text={"Certo"} onClick={handleGoToHome} />
                </WindowWrapper>
                <ImageAvatar
                        src={Yeah}
                        alt="Avatar daniel" />
            </Window>
        </Container>
    )
}

export default PopupUserCV;
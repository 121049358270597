import { useEffect, useState } from "react";
import Search from "../../Components/Search";
import JobItem from "../../Components/JobItem";
import HeaderPerfil from "../../Components/HeaderPerfil";

import { ContainerJobs, LoadingContainer, Spinner } from "./style";
import Popup from "../../Components/Popup";
import { GetJobs } from "../../service/request";
import { PopupData } from "../../Components/Popup/ComponentPopup";



export function Home() {

    const [jobs, setJobs] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [popup, setPopup] = useState(false);
    const [popupData, setPopupData] = useState<PopupData>();


    const FetchJobs = async () => {
        const { data, error } = await GetJobs().finally(() => setLoading(false));
        if (Array.isArray(data)) { // Verifica se data é uma array antes de atualizar o estado
            setJobs(data);
        }
        if (error) {
            setPopup(true);
            setPopupData({ code: error.code, title: error.title, description: error.description });
        }
    }

    useEffect(() => {
        FetchJobs();
    }, []);

    return (
        <>
            {popup && popupData && (
                <Popup dataPopup={popupData} />
            )}
            <HeaderPerfil
                backButton={false}
                notificationButton={true}
                addJobButton={true} />
            <Search />
            {loading ? (
                <LoadingContainer>
                    <Spinner />
                </LoadingContainer>
            ) : (
                <ContainerJobs>
                    {jobs?.map((job, key) => (
                        <JobItem key={key} job={job} />
                    ))}
                </ContainerJobs>
            )}
        </>
    );
};
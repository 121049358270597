import { useEffect, useState } from "react";
import {
  Container, ContentTop, ContentBottom,
  RowTags, Salary, TabContent, TabHeader,
  Tab, ContentList, ButtonBack, ContentButtonsTop, ButtonGroup, ExpireDate
} from "./style";
import { useParams, useNavigate } from "react-router-dom";
import HeaderJob from "../../Components/HeaderJob";
import Button from "../../Components/Button";
import Tag from "../../Components/ButtonTag";
import ButtonSquare from "../../Components/ButtonSquare";
import BackButton from "../../assets/backbutton-white.png";
import { GetJobID } from "../../service/request";
import { IJob } from "../../Components/JobItem";
import { LoadingContainer, Spinner } from "../Home/style";

const JobDetails = () => {
  const { jobId
  } = useParams();

  const [job, setJob] = useState<IJob>();
  const [activeTab, setActiveTab] = useState("tabOne");
  const navigate = useNavigate();

  const handleTabClick = (tab: "tabOne" | "tabTwo") => {
    setActiveTab(tab);
  };

  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await GetJobID(jobId || "1");
        if (result.data) {
          setJob(result.data);
        } else {
          // Lide com o caso de erro aqui
          console.error("Erro ao buscar os detalhes do trabalho");
        }
      } catch (error) {
        // Lide com o caso de erro aqui
        console.error("Erro ao buscar os detalhes do trabalho", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      {job ? (
        <>
          <ContentTop>
            <ContentButtonsTop>
              <ButtonSquare image={BackButton} onClick={handleClick} />
            </ContentButtonsTop>
            <HeaderJob
              title={job.title ? job.title : ""}
              description={job.description ? job.description : ""}
              avatar={job.image ? job.image : ""} />
            <RowTags>
              {/*{Array.isArray(job.tag) && job.tag.map((tag: string, index: number) => (
                <Tag key={index} text={tag} />
              ))}
              */}
              <Tag key={0} text={"Java"} />
              <Tag key={1} text={"End"} />
              <Tag key={2} text={"Híbrido"} />
            </RowTags>
            <Salary>{(job.salary ? "R$ " + job.salary + "/mês" : "Salário a Combinar")}</Salary>
          </ContentTop>
          <ContentBottom>
            <TabHeader>
              <Tab onClick={() => handleTabClick("tabOne")}>Descrição</Tab>
              <Tab onClick={() => handleTabClick("tabTwo")}>Sobre</Tab>
            </TabHeader>
            <TabContent>
              {activeTab === "tabOne" && (
                <ContentList>
                  <li>
                    <h3>Local:</h3>
                    <p>{job.location || "Recife"}</p>
                  </li>
                  <li>
                    <h3>Benefícios:</h3>
                    <p>{job.benefits || "CLT"}</p>
                  </li>
                  <li>
                    <h3>Requisitos:</h3>
                    <p>{job.requirements || "Ensino Médio Completo"}</p>
                  </li>
                  <li>
                    <h3>Atividades:</h3>
                    <p>{job.companyName || "Atribuições"}</p>
                  </li>
                </ContentList>
              )}
              {activeTab === "tabTwo" && (
                <ContentList>
                  <li>Sobre...</li>
                </ContentList>
              )}
            </TabContent>
          </ContentBottom>
          <ButtonGroup>
            <ExpireDate>Tempo restante: {job.expireAt || "0 dias"}</ExpireDate>
            <Button text={"Candidatar-se"} />
          </ButtonGroup>
        </>
      ) : (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      )}

    </Container>
  );
};

export default JobDetails;
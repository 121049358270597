import React, { useState } from 'react';
import {Container, ContentWrapper, Description, Title, Image, IconLike, DescLike, DescData, ZoomLink} from './style';
import JobImageDefault from '../../assets/job-image-default.png';
import Heart from '../../assets/heart.svg'
import { Link } from 'react-router-dom';

export interface IJob {
    jobId: number;
    title: string;
    image: string;
    location: string;
    isPCD: boolean;
    categoryJob: string;
    benefits: string[];
    requirements: string[];
    salary: string;
    description: string;
    companyName: string;
    companyEmail: string;
    tag: string;
    expireAt: string;
    likes: number;
    createdAt: string;
}

export const JobItem:React.FC<{job: IJob}> = ({job}) => {
    const [likes, setLikes] = useState(job.likes ?? 0);

    const handleLikeClick = () => {
        setLikes((prevLikes: number) => prevLikes + 1);
      };
    return (
        <Container>
                <ContentWrapper>
                        <ZoomLink to={`/job/${job.jobId}`}>
                            <Image src={job.image !== "" ? job.image : JobImageDefault} alt="Job Image"/>
                        </ZoomLink>
                        <Link to={`/job/${job.jobId}`}>
                            <Title>{job.title ?? "Titulo não encontrado"}</Title>
                        </Link>
                    <Description>
                        <DescData>{job.createdAt ? job.createdAt.toString() : "1 abril 2023"}</DescData>
                        <DescLike onClick={handleLikeClick}>
                            <p>{likes ?? 0} curtidas</p>
                            <IconLike src={Heart}/>
                        </DescLike>
                    </Description>
                </ContentWrapper>
        </Container>
    );
};

export default JobItem
import styled from 'styled-components';
interface SidebarProps {
    isOpen: boolean;
}
export const HomeContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const TopBar = styled.div<SidebarProps>`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 16px;
    width: 100%;
    background-color: #0693e3;
    color: white;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    position: fixed;
    left: ${({ isOpen }) => (isOpen ? '-100%' : '0%')};
    transition: left 0.5s ease-out;
`;
export const ContentContainer = styled.div<SidebarProps>`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 4rem;
  width: 100%;
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'none')};
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  left: ${({ isOpen }) => (isOpen ? '70%' : '0%')};
    transition: left 0.4s ease-out;
`;

export const MenuButton = styled.button`
  display: none;
  color: white;
  background-color: transparent;
  border: 2px solid white;
  border-radius: .5rem;
  width: 32px;
  height: 32px;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
import styled from "styled-components";

interface BarProgressProps {
    width: string;
}

export const Card = styled.div`
  margin-top: 16px;
  width: 243px;
`;
export const Image = styled.img`
    display: flex;
    max-width: 243px;
    align-items: center;
    justify-content: center;
`;
export const ContentImage = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
`;
export const ContentInfoCard = styled.div`
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: column;
    padding-top:.5rem;
`;
export const ButtonOptions = styled.button`
    color: #2d2f31;
    background-color: #fff;
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: .6rem;
    right: .6rem;
    border: none;
    cursor: pointer;
`;
export const Title = styled.a`
    font-size: 1rem;
    color: #2d2f31;
    font-weight: 600;
    text-decoration: none;
`;
export const Instructor = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    color: #6a6f73;
    padding-top: .4rem;
    padding-bottom: .4rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.4;
`;
export const BarProgress = styled.div<BarProgressProps>`
    width: ${({ width }) => width};
    height: .2rem;
    background-color: #0693e3;
    border-radius: 10px;
`;
export const BackgroundProgress = styled.div`
    background: #ccc;
    border-radius: 10px;
`;
export const InfoProgress = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: .3rem;
    font-size: .8rem;
    color: #6a6f73;
    font-weight: 400;
    line-height: 1.4;
`;
export const ClassificationButton = styled.button`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    border: none;
    background-color: transparent;
    cursor: pointer;
`;
export const Stars = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.2rem;
    color: #b4690e;
`;
import styled, { keyframes } from 'styled-components'

export const ContainerJobs = styled.div`
    flex-direction: column;
    display: flex;
    padding-top: 1rem;
    align-items: center;
    margin: 0 2rem;
    gap: 10px;
    & > *:not(:first-child) {
        padding-top: 10px;
    }
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;
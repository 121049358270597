import InputBase from '@mui/material/InputBase';
import styled from "styled-components";

export const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
`;
export const SearchContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    box-sizing: border-box;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;
export const SearchIconWrapper = styled.div`
    padding: 0 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const StyledInputBase = styled(InputBase)`
    color: inherit;
    border-radius: 1rem;
    & .MuiInputBase-input {
      padding: 10px 10px 10px 0;
      transition: all 0.3s ease-in-out;
      width: 9ch;
        &:focus {
            width: 15ch;
        }
    }
`;



/*export const SearchContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
            width: '20ch',
        },
        },
    },
}));
*/
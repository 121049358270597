import SearchIcon from '@mui/icons-material/Search';
import { SearchContainerWrapper, SearchContainer } from './style';
import {FormControl, InputLabel, OutlinedInput} from "@mui/material";

const Search:React.FC = () => {
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
  return (
    <SearchContainer>
        <SearchContainerWrapper>
            <FormControl fullWidth sx={{ m: 1, width: '90%'}}>
                <InputLabel htmlFor="outlined-adornment-amount">
                    Digite o nome da vaga
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<SearchIcon sx={{ color: "#0FB8E3" }} />}
                    label="Digite o nome da vaga"
                />
            </FormControl>
        </SearchContainerWrapper>
    </SearchContainer>
  );
};
export default Search

function setAnchorEl(currentTarget: EventTarget & HTMLElement) {
    throw new Error('Function not implemented.');
}

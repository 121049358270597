import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 60%);
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  align-items: flex-end;
`;
export const Window = styled.div`
    width: 344px;
    height: 345px;
    border-radius: 20px;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    background-color: #fff;
    overflow: hidden;
`;
export const WindowWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;
export const Header = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
`;
export const GroupDescription = styled.div`
    display: flex;
    max-width: 60%;
    flex-direction: column;
`;
export const ImageAvatar = styled.img`
    position: relative;
    left: 46%;
    transform: translateY(-85%);
    width: 280px; 
    height: 280px; 
    object-fit: cover;
`;
import React, { useState } from 'react';
import { Overlay, Modal, Title, Description, ButtonContainer, Button } from './style';

export interface PopupData {
    title: string;
    code: string;
    description: string;
    textButton?: string;
}
const ComponentPopup: React.FC<PopupData> = ({ title, code, description, textButton }) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            {isOpen && (
                <Overlay>
                    <Modal>
                        <Title>{title || "Error: "} {code || "PA-0"}</Title>
                        <Description>
                            {description || "Erro desconhecido"}
                        </Description>
                        <ButtonContainer>
                            <Button onClick={handleClose}>
                                {textButton || "Ok"}
                            </Button>
                        </ButtonContainer>
                    </Modal>
                </Overlay>
            )}
        </>
    )
};

export default ComponentPopup
import React from 'react';
import { SidebarContainer, SidebarMenu, SidebarMenuItem, ButtonClose } from './style';

const Sidebar: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => (
  <SidebarContainer isOpen={isOpen}>
    <h2>Menu</h2>
    <SidebarMenu>
      <SidebarMenuItem>Meu Aprendizado</SidebarMenuItem>
      <SidebarMenuItem>Obtenha nosso aplicativo</SidebarMenuItem>
      <SidebarMenuItem>Convidar amigos</SidebarMenuItem>
      <SidebarMenuItem>Ajuda</SidebarMenuItem>
      <SidebarMenuItem>Portugues</SidebarMenuItem>
    </SidebarMenu>
    <ButtonClose onClick={onClose}>X</ButtonClose>
  </SidebarContainer>
);

export default Sidebar;
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, GradientBackground, Image } from "./style";
import IconSuccess from "../../assets/success.svg";
const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Redirecionar para a página inicial após 3 segundos
      navigate("/");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <Container>
      <GradientBackground />
      <Image src={IconSuccess} alt="Imagem de sucesso" />
        <h1>Vaga Criada com sucesso!</h1>
    </Container>
  );
};

export default Success;
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import { Starting } from './page/Starting';
import { Home } from "./page/Home";
import Login from "./page/Login";
import Register from "./page/Register";
import CreateJobs from './page/CreateJobs';
import JobDetails from './page/JobDetails';
import CreateUser from './page/CreateUser';
import Course from "./page/Course";

function ComponentRouter() {
    return (
        <BrowserRouter basename="">
            <Routes>
                <Route path="/" element={<Starting />} />
                <Route path="/home" element={<Home />} />
                <Route path="/course" element={<Course />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/createUser" element={<CreateUser />} />
                <Route path="/createJobs" element={<CreateJobs />} />
                <Route path="/job/:jobId" element={<JobDetails />} />
            </Routes>
        </BrowserRouter>
    );
}

export default ComponentRouter;

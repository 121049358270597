import styled from 'styled-components';
export const Container = styled.div`
  
`;
export const ButtonSkip = styled.button`
    max-width: 10rem;
    width: 100%;
    height: 2.1rem;
    padding: 0.4rem 1.3rem;
    background-color: #777777;
    
    color: #fcfcfc;
    font-family: 'Roboto', sans-serif;
    font-size: min(1.1rem, 5vw);
    font-weight: bold;
    
    border: none;
    cursor: pointer;
    border-radius: 15rem;
    outline: none;
`;
import React from "react";
import {Button} from "./style";
import IconBack from "../../assets/backbutton.png";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    image: string
    onClick?: () => void;
}
const ButtonSquare:React.FC<IButton> = ({image, onClick }) => {
    return (
        <Button onClick={onClick}>
            <img src={image || IconBack} alt="Icon Back"/>
        </Button>
    );
};

export default ButtonSquare;
import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { singIn } from "../../service/user";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../Components/Button";
import {
    Content,
    ContentButton,
    ContentError,
    ContentInput,
    FormContent,
    Header,
    ImgLogo,
    SubTitle, TextCreate,
    Title
} from "../Login/style";
import EmpregosRecLogo from "../../assets/emprego-recife-logo.svg";
import { TextField } from "@mui/material";
import { GotoLogin, GroupBottom } from "./style";

type SingIn = {
    email: string
    password: string
    confirmPassword: string
}

const schema = yup.object({
    email: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().required()
})
const SingIn: React.FC = () => {
    const navigate = useNavigate()
    
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<SingIn>({ resolver: yupResolver(schema) })
    const handlerGotoSingIn = () => {
        navigate("/login")
    }
    const handleRegister = async (data: SingIn) => {
        navigate("/createUser")
        //const { res, error } = await singIn(data)
        //    .finally();
        //if (res) console.log("Conta criada com sucesso !!")
    }

    return (
        <Content>
            <Header>
                <ImgLogo src={EmpregosRecLogo} alt="Empregos Recife" />
                <Title>Crie sua conta</Title>
                <SubTitle>Empregos Recife</SubTitle>
            </Header>
            <FormContent onSubmit={handleSubmit(handleRegister)}>
                <ContentInput>
                    <TextField size="small" sx={{ width: "100%", background: "transparent" }} {...register("email")} label="Email" />
                    <p>{errors.email?.message}</p>
                </ContentInput>
                <ContentInput>
                    <TextField size="small" sx={{ width: "100%", background: "transparent" }} {...register("password")} label="Senha" type="password" />
                    <ContentError>{errors.password?.message}</ContentError>
                </ContentInput>
                <ContentInput>
                    <TextField size="small" sx={{ width: "100%", background: "transparent" }} {...register("confirmPassword")} label="Confirma senha" type="password" />
                    <ContentError>{errors.password?.message}</ContentError>
                </ContentInput>
                <ContentButton>
                    <Button text={"Registrar"} />
                </ContentButton>
                    <TextCreate>Ao clicar em começar, você indica que leu e conconrda com nossos <span>Termos de uso</span></TextCreate>
                <GroupBottom>
                    <TextCreate>Já tem uma conta? <GotoLogin onClick={handlerGotoSingIn}>Entrar</GotoLogin></TextCreate>
                </GroupBottom>
                {/*
                <TextOption>Registrar com</TextOption>
                <ContentSocial>
                    <ButtonSocial>Google</ButtonSocial>
                    <ButtonSocial>Facebook</ButtonSocial>
                </ContentSocial>
                */}
            </FormContent>
        </Content>
    );
};

export default SingIn;
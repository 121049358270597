import {api, IError} from "./api";
import {AxiosError} from "axios";

type LoginProps ={
    email: string
    password: string
}
export const login = async (data:LoginProps ) =>{
    const res = await api.post("",data)
        .then((res)=>res.data)
        .catch((error)=>error as AxiosError<IError>)

    if (!(res instanceof Error)){
        return {res: res}
    }else{
        return {error: "Servidor fora do ar."}
    }
}


type singInProps ={
    email: string
    password: string
    confirmPassword: string
}
export const singIn = async (data:singInProps ) =>{
    const res = await api.post("",data)
        .then((res)=>res.data)
        .catch((error)=>error as AxiosError<IError>)

    if (!(res instanceof Error)){
        return {res: res}
    }else{
        return {error: "Servidor fora do ar."}
    }
}
import React from "react";
import {ButtonContent} from "./style";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    text: JSX.Element | JSX.Element[] | string
    onClick?: () => void;
}
const Button:React.FC<IButton> = ({text, onClick }) => {
    return (
        <ButtonContent onClick={onClick}>
            {text}
        </ButtonContent>
    );
};

export default Button;
import React from "react";
import CopyIcon from "../../assets/copy.svg";
import { Container, BoxName, Avatar, Name, UserId } from "./style";

import AvatarDefault from "../../assets/perfil-image-default.png";

interface Props {
    userId: string;
    username: string;
    avatar: string;
}

const Perfil: React.FC<Props> = ({ userId, username, avatar }) => {
    return (
        <Container>
            <Avatar src={avatar ? "" : (AvatarDefault) } />
            <BoxName>
                <Name>{username}</Name>
                <UserId>{userId}<img src={CopyIcon}/></UserId>
            </BoxName>
            
        </Container>
    );
}
export default Perfil;
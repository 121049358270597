import styled from 'styled-components';

export const Container = styled.div`
    background-color: #FFF;
    width: 100%;
    height: 3.2rem;
`
export const ContentWrapper = styled.div`
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
export const ImageButton = styled.img`
    &:hover{
        cursor: pointer;
    }
`;
export const LeftBox = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    .backButton{
        width: 31px;
        height: 31px;
    }
`
export const RightBox = styled.div`
    display: flex;
    align-items: center;

    .notificationButton{
        margin-right: 20px;
        width: 19.87px;
        height: 24.217px;
    }
    .AddJobButton{
        margin-right: 20px;
        width: 24px;
        height: 24px;
    }
`
export const Placeholder = styled.div`
  width: 31px;
  height: 31px;
`;
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup"
import Register from "../Register";
import { useNavigate } from 'react-router-dom';
import {login} from "../../service/user";
import Button from "../../Components/Button";
import {
    ButtonSocial,
    Content,
    ContentButton,
    ContentError,
    ContentInput,
    ContentRecover, ContentSocial,
    CreateAccont,
    FormContent,
    Header, ImgLogo,
    SubTitle,
    TextCreate, TextOption,
    Title
} from "./style";
import {TextField} from "@mui/material";
import EmpregosRecLogo from "../../assets/emprego-recife-logo.svg";

type Login={
    email:string
    password: string
}

const schema = yup.object({
    email: yup.string().required(),
    password: yup.string().required()
})
const Login:React.FC = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Login>({resolver: yupResolver(schema)})

    const handleCreateAccount = () =>{
        navigate("/register")
    }
    const handleLogin = async (data:Login) =>{
        navigate("/home")
        const { res,error } = await login(data)
        if (res) console.log("Login feito com sucesso")
    }
    return (
        <Content>
            <Header>
                <ImgLogo src={EmpregosRecLogo} alt="Empregos Recife"/>
                <Title>Acesse sua conta</Title>
                <SubTitle>Empregos Recife</SubTitle>
            </Header>
            <FormContent onSubmit={handleSubmit(handleLogin)}>
                <ContentInput>
                    <TextField size="small" sx={{width:"100%" ,background:"transparent"}} {...register("email")} label="Email" type="email" />
                    <p>{errors.email?.message}</p>
                </ContentInput> 
                <ContentInput>
                    <TextField size="small" sx={{width:"100%" ,background:"transparent"}} {...register("password")} label="Senha" type="password" />
                    <ContentError>{errors.password?.message}</ContentError>
                </ContentInput>
                <ContentRecover>Esqueci minha senha</ContentRecover>
                <ContentButton>
                    <Button text={"Login"}/>
                </ContentButton>
                <TextCreate>Ainda não possui conta? <CreateAccont onClick={handleCreateAccount}>Criar conta</CreateAccont></TextCreate>
                <TextOption>Logar com</TextOption>
                <ContentSocial>
                    <ButtonSocial>Google</ButtonSocial>
                    <ButtonSocial>Facebook</ButtonSocial>
                </ContentSocial>
            </FormContent>
        </Content>
    );
};

export default Login
import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Cor preta com 50% de transparência */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Z-index alto para garantir que fique na frente de outros elementos */
`;

export const Modal = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Button = styled.button`
  padding: 10px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
import Perfil from "../Perfil"
import { Container, ContentWrapper, RightBox, LeftBox, Placeholder, ImageButton } from "./style"
import { useNavigate } from "react-router-dom";

import IconBack from "../../assets/backbutton.png";
import IconNotification from "../../assets/notification.svg";
import IconJob from "../../assets/job.svg";


interface Props {
    backButton: boolean;
    notificationButton: boolean;
    addJobButton: boolean;
}

export const Menu:React.FC<Props> = ({backButton, notificationButton, addJobButton}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/createJobs")
    };
    const handleBack = () => {
        navigate(-1)
    }

    return (
        <Container>
            <ContentWrapper>
                <LeftBox>
                    {backButton ? ( 
                        <ImageButton className="backButton" src={IconBack} onClick={handleBack} alt="Voltar"/>
                    ): (
                        <Placeholder/>
                    )}
                    <Perfil 
                        userId={"ID: 1234567890"} 
                        username={"Marilia Gabriela"} 
                        avatar={""} />
                </LeftBox>
                <RightBox>
                    {addJobButton && (
                        <ImageButton className="AddJobButton" 
                            src={IconJob} 
                            alt="Adicionar emprego"
                            onClick={handleClick} />
                    )}
                    {notificationButton ? (
                        <ImageButton className="notificationButton" src={IconNotification} alt="Notificações"/>
                    ) : (
                        <Placeholder />
                    )}
                </RightBox>
            </ContentWrapper>
        </Container>
    )
}
export default Menu;
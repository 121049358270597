import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 388px;
`;
export const ZoomLink = styled(Link)`
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
export const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`;
export const Image = styled.img`
    max-width: 400px;
    width: 100%;
    height: 126px;
    flex-shrink: 0;
    object-fit: cover;
    -webkit-box-shadow: 0px 9px 17px -8px rgba(0,0,0,0.66);
    -moz-box-shadow: 0px 9px 17px -8px rgba(0,0,0,0.66);
    box-shadow: 0px 9px 17px -8px rgba(0,0,0,0.66);
`;

export const Title = styled.h2`
    margin-top: 1rem;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
`;

export const Description = styled.div`
    padding-top: 1rem;
    font-size: 0.8rem;
    font-weight: 400;
    color: #000;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  
`;

export const DescData = styled.p`
    font-size: 0.95rem;
    font-weight: 400;
`;

export const DescLike = styled.div`
    display: flex;
    p{
      font-weight: 400;
      font-size: 0.95rem;
      padding-right: 0.8rem;    
    }
`;
export const IconLike =styled.img`
    width: 1.3rem;
    &:hover{
        cursor: pointer;
    }
`;

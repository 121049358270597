import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import Button from '../../Components/Button';
import { ActiveDot, ButtonContainer, Container, ContainerBottom, ContainerTop, Description, GroupDots, PaginationDot, Title, TitleContainer } from './style';

export function Starting() {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();

    const ApresetationArray = [
        {
            title: 'Bem vindo ao Empregos Recife',
            description: 'A plataforma Empregos Recife é totalmente gratuita'
        },
        {
            title: 'Vagas todos os dias!',
            description: 'Uma ampla variedade de oportunidades de emprego em Recife e região.'
        },
        {
            title: 'Tudo por nossa conta!',
            description: 'Acompanhe seu perfil, gerencie suas pontuações, currículo e muito mais.'
        }
    ]
    const nextTitleAndDescription = () => {
        if (currentPage < ApresetationArray.length - 1) {
            setCurrentPage(currentPage + 1);
        } else {
            navigate('/login');
        }
    };
    return (
        <Container >
            <ContainerTop>
                <img className="logo" src={Logo} alt="Logo" />
            </ContainerTop>
            <ContainerBottom>
                <TitleContainer>
                    <Title>{ApresetationArray[currentPage].title}</Title>
                    <Description>{ApresetationArray[currentPage].description}</Description>
                </TitleContainer>
                <ButtonContainer>
                    <GroupDots style={{ marginBottom: '30px' }}>
                        {ApresetationArray.map((_, index) => (
                            index === currentPage ? <ActiveDot key={index} /> : <PaginationDot key={index} />
                        ))}
                    </GroupDots>
                    <Button 
                    onClick={nextTitleAndDescription} 
                    text={currentPage === ApresetationArray.length - 1 ? 'Começar' : 'Próximo'} />
                </ButtonContainer>
            </ContainerBottom>
        </Container>
    );
};
export const benefitsArray = [
    { benefit: "Alimentação na empresa", selected: false },
    { benefit: "Comissão", selected: false },
    { benefit: "Curso", selected: false },
    { benefit: "Desconto em parceiros", selected: false },
    { benefit: "Folga aniversário", selected: false },
    { benefit: "Plano de carreira", selected: false },
    { benefit: "Plano odontológico", selected: false },
    { benefit: "Seguro de vida", selected: false },
    { benefit: "Vale alimentação", selected: false },
    { benefit: "Vale transporte", selected: false },
];
export const requirementsArray = [
    { requirement: "Ensino Médio Completo" },
    { requirement: "Experiência de 6 meses" },
    { requirement: "Disponibilidade de horário" },
];
export const skillsArray = [
    { job: "idTeste" , skill: "Alcançar metas", selected: false },
    { job: "idTeste" , skill: "Atender", selected: false },
    { job: "idTeste" , skill: "Calcular", selected: false },
    { job: "idTeste" , skill: "Comunicar-se", selected: false },
    { job: "idTeste" , skill: "Criativo", selected: false },
    { job: "idTeste" , skill: "Cumprir prazos", selected: false },
    { job: "idTeste" , skill: "Editar vídeo", selected: false },
    { job: "idTeste" , skill: "Escrever", selected: false },
    { job: "idTeste" , skill: "Falar outro idioma", selected: false },
    { job: "idTeste" , skill: "Negociar", selected: false },
    { job: "idTeste" , skill: "Organizar", selected: false },
    { job: "idTeste" , skill: "Recepcionar", selected: false },
    { job: "idTeste" , skill: "Redes sociais", selected: false },
    { job: "idTeste" , skill: "Trabalhar em equipe", selected: false },
    { job: "idTeste" , skill: "Vender", selected: false },
];
export const locations = [
    "Abreu e Lima",
    "Aflitos",
    "Afogados",
    "Alto do Mandu",
    "Alto José Bonifácio",
    "Alto José do Pinho",
    "Boa Viagem",
    "Cabo de Santo Agostinho",
    "Camaragibe",
    "Candeias",
    "Campo Grande",
    "Casa Forte",
    "Caxangá",
    "Igarassu",
    "Ipojuca",
    "Itamaracá",
    "Itapissuma",
    "Jaboatão dos Guararapes",
    "Moreno",
    "Olinda",
    "Paulista",
    "Piedade",
    "Prazeres",
    "Recife",
    "São Lourenço da Mata",
];
export const categorysArray = [
    "Analista",
    "Atendimento",
    "Balcão",
    "Caixa",
    "Coordenação",
    "Estoque",
    "Gerência",
    "Jovem aprendiz",
    "Limpeza",
    "Manutenção",
    "Obra",
    "Portaria",
    "Recepção",
    "Supervisão",
    "Telemarketing",
    "Vendas",
];
export const statesArray = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espirito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso do Sul",
    "Mato Grosso",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
];

export const nationalityArray = [
    "Afegã(o)",
    "Africana(o)",
    "Alandesa(o)",
    "Albanesa(o)",
    "Alemã(o)",
    "Americana(o)",
    "Andorrana(o)",
    "Angolana(o)",
    "Anguilana(o)",
    "Antiguana(o)",
    "Arabia Saudita",
    "Argelina(o)",
    "Argentina(o)",
    "Armênia(o)",
    "Arubana(o)",
    "Australiana(o)",
    "Austríaca(o)",
    "Azerbaijana(o)",
    "Bahamense",
    "Bahreiniana(o)",
    "Bangladesa(o)",
    "Barbadiana(o)",
    "Belga(o)",
    "Belizenha(o)",
    "Beninense",
    "Bermudense",
    "Bielorrussa(o)",
    "Birmanesa(o)",
    "Boliviana(o)",
    "Bósnia(o)",
    "Botsuanesa(o)",
    "Brasileira(o)",
    "Bruneiana(o)",
    "Búlgara(o)",
    "Burquinês",
    "Burundinesa(o)",
    "Butanesa(o)",
    "Cabo-verdiana(o)",
    "Camaronense",
    "Cambojana(o)",
    "Canadense",
    "Canário",
    "Cazaque",
    "Chadiana(o)",
    "Chilena(o)",
    "Chinesa(o)",
    "Cingapuriana(o)",
    "Cipriota",
    "Colombiana(o)",
    "Comorense",
    "Congolês",
    "Cookense",
    "Costa-marfinense",
    "Costarriquenha(o)",
    "Croata",
    "Cubana(o)",
    "Curda(o)",
    "Dinamarquesa(o)",
    "Djibutiana(o)",
    "Dominicana(o)",
    "Egípcia(o)",
    "Emiradense",
    "Equatoriana(o)",
    "Eritreia(o)",
    "Eslovaca(o)",
    "Eslovena(o)",
    "Espanhola(o)",
    "Estado-unidense",
    "Estoniana(o)",
    "Etíope",
    "Faroense",
    "Fijiana(o)",
    "Filipina(o)",
    "Finlandesa(o)",
    "Francesa(o)",
    "Gabonesa(o)",
    "Gambiana(o)",
    "Ganesa(o)",
    "Georgiana(o)",
    "Gibraltariana(o)",
    "Granadina(o)",
    "Grego",
    "Groenlandesa(o)",
    "Guadalupense",
    "Guamês",
    "Guatemalense",
    "Guianense",
    "Guianense(o)",
    "Guinéu-equatoriano",
]

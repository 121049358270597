import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`;
export const ContentTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    width: 100%;
    flex: 0.25;
    background: linear-gradient(to right, #11B9E4, #0A4AB0);
`;
export const ContentButtonsTop = styled.div`
    display: flex;
    width: 100%;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
`;
export const ContentBottom = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`;
export const ButtonBack = styled.button`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 40px;
    height: 40px;
    cursor: pointer;
`;
export const RowTags = styled.div`
    display: flex;
    flex: 0.3;
    flex-wrap: wrap;    
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 100%;
`;
export const Salary = styled.div`
    display: flex;
    font-size: 1.3rem;
    font-weight: 500;
`;
export const ButtonGroup = styled.div`
    margin-bottom: 2rem;
    
`;
export const ExpireDate = styled.p`
    margin-bottom: 1rem;
`;
export const HeaderJob = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flex-direction: column;
    }
`;

export const TabHeader = styled.div`
    display: flex;
    min-height: 2.5rem;
    justify-content: center;
    align-items: center;
`;

export const TabContent = styled.div`
    display: flex;
    padding-top: 1rem;;
    flex: 1;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    flex-direction: column;
    li {
        margin-bottom: 8px;
        color: #777;
    }
`;
export const Tab = styled.div`
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;

    &:hover {
        background-color: #f0f0f0;
    }

    &.active {
        background-color: #0A4AB0;
        color: #0A4AB0;
    }
`;
export const ContentList = styled.ul`
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    list-style-type: none;
    padding: 0;
    margin: 0;
`;
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    width: 202px;
    height: 43px;
`
export const BoxName = styled.div`
    flex-direction: column;
    align-items: flex-start;
`

export const Avatar = styled.img`
    width: 51px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 50%;
`
export const Name = styled.h1`
    font-size: 18px;
    font-style: normal;
    color: #414141;
    font-weight: 700;
`
export const UserId = styled.p`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #777;
    align-items: baseline;
    img{
        padding-left: 5px;
        height: 11px;
    }
`
import styled from 'styled-components';

interface SidebarProps {
    isOpen: boolean;
}
export const SidebarMenu = styled.ul`
    list-style: none;
    padding-top: 2rem;
    margin: 0;
`;
export const SidebarMenuItem = styled.li`
    padding-top: 8px;
    padding-bottom: 8px;
`;
export const ButtonClose = styled.button`
    position: absolute;
    width: 32px;
    height: 32px;
    top: 16px;
    right: 16px;
    background-color: transparent;
    border: 2px solid white;
    border-radius: .5rem;
    color: white;
    font-size: 16px;
    cursor: pointer;
`;
export const SidebarContainer = styled.div<SidebarProps>`
  width: 250px;
  height: 100vh;
  background-color: #0693e3;
  color: white;
  padding: 16px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  transition: left 0.3s ease;

  @media screen and (max-width: 768px) {
    left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  }
`;
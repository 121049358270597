import styled from "styled-components";
import background from '../../assets/background.jpg';
export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 1100px){
    align-items: flex-end;
    padding-right: calc(15% - 10rem);
    &:before  {
      content: "";
      background-image: url('${background}');
      background-size: cover;
      background-position: center;
      width: 70%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  
`;

export const Header = styled.div`
  width: 100%;
  max-width: 20rem;
  
  margin-bottom: 2rem;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ImgLogo = styled.img`
  width: 6rem;
  height: 6rem;
`;

export const Title = styled.h1`
  font-size: 1.3rem;
`;

export const SubTitle = styled.h2`
  font-size: 1.4rem;
  color: #0FB8E3;
`;

export const FormContent = styled.form`
  width: 100%;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentButton = styled.div`
  width: 100%;
  max-width: 20rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  margin-bottom: 1rem;
`;

export const ContentError = styled.div`
  
`;

export const ContentRecover = styled.div`
  margin-bottom: 1.2rem;
  padding: 0 0.4rem;
  width: 100%;
  max-width: 20rem;
  
  font-weight: 500;
  color: #0FB8E3;
`;

export const TextCreate = styled.div`
  margin-top: 1rem;
  padding: 0 0.4rem;
  
  width: 100%;
  max-width: 20rem;

  font-weight: 400;
  font-size: 0.9rem;
  color: #A7AFB7;
  
  span{
    color: #0FB8E3;
  }
`;

export const TextOption = styled.p`
  margin: 2rem 0;
  width: 100%;
  max-width: 20rem;

  font-weight: 300;
  font-size: 0.8rem;
  text-align: center;
`;

export const ContentSocial = styled.div`
  width: 100%;
  max-width: 20rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CreateAccont = styled.span`
  color: #0FB8E3;
  font-weight: 500;
  cursor: pointer;
`;

export const ButtonSocial = styled.button`
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  background: transparent;
  width: 11rem;
  
  font-size: 0.95rem;
  font-weight: 500;

  border: none;
  border-radius: 0 0 15px 15px;
  outline: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
import ComponentPopup from "./ComponentPopup";

interface PopupProps {
    dataPopup: {
        title: string;
        code: string;
        description: string;
    }
}

const Popup:React.FC<PopupProps> = ({dataPopup}) => {
    const popups: { [key: string]: JSX.Element } = {
        "200": <ComponentPopup title={dataPopup.title} code={dataPopup.code} description={dataPopup.description} />,
        "400": <ComponentPopup title={dataPopup.title} code={dataPopup.code} description={dataPopup.description} />,
    }

    return popups[dataPopup.code] || null;
}

export default Popup;
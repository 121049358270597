import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
export const Logo = styled.img`
    display: flex;
    justify-content: center;
    width: 77px;
    height: 77px;
    flex-shrink: 0;
    border-radius: 42px;    
`;
export const Title = styled.h1`
    display: flex;
    justify-content: center;
    padding-top: 0.3rem;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
`;
export const ThinParagraph = styled.p`
    display: flex;
    justify-content: center;
    font-weight: 300;
`;